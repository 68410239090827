<template>
  <section class="background--views">
    <v-container>
      <component
        :is="component"
        :isEdit="isEdit"
        :item="item"
        @changeComponent="changeComponent"
        @edit="edit"
      ></component>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "InternetPlans",
  components: {
    InternetPlansTable: () =>
      import("../components/internet-plans/InternetPlansTable"),
    InternetPlansNew: () =>
      import("../components/internet-plans/InternetPlansNew"),
    InternetPlansDetails: () =>
      import("../components/internet-plans/InternetPlansDetails"),
  },
  data: () => ({
    component: "InternetPlansTable",
    isEdit: false,
    internetPlan: {
      type: "HOGAR",
      name: "",
      price: 0,
      speed: 5,
      description: "",
    },
    item: {},
  }),
  methods: {
    changeComponent(component, item = {}) {
      this.isEdit = false;
      this.item = item;
      this.component = component;
    },
    edit(item) {
      this.item = Object.assign({}, item);
      this.isEdit = true;
      this.component = "InternetPlansNew";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>